'use client'
import React, { useState, useEffect, useMemo, useContext } from 'react'
import { Radio, Select, Space } from 'antd'
import { usePathname } from 'next/navigation'
import classNames from 'classnames'

import { getCountryKey } from 'helpers/country'
import { getCountryId } from 'helpers/country'
import { addEventTag, addParams } from 'helpers/utilities'
import { getValueById } from 'helpers/config/getValueById'
import { getLang, countryForCurrency } from 'helpers/country'
import {
  getSalaryOptions,
  getCountryList,
  getCurrencyList,
  initSalaryTypeLists
} from 'helpers/jobPayloadFormatter'

import {
  createUserPreferencesService,
  updateUserPreferencesService
} from 'store/services/users/addUserPreferences'

import { LinkContext } from 'app/components/providers/linkProvider'
import Cascader from 'components/Cascader'
import { getUrlSearchString, isChatProcess, jumpPage } from '../utils'

import JobFunction from '../JobFunction'
import LoadingButton from '../Common/LoadingButton'
import styles from './index.mobile.module.scss'
import SafeInfo from '../Common/SafeInfo'
import { ArrowIcon } from './icons'
import { useMainResumeContext } from '../Main/context'
import { removeEmptyOrNullValues } from 'helpers/formatter'
import { cloneDeep } from 'lodash-es'
import { trackAdjustEventBySite } from 'app/components/adjust'
import DesiredHeaderMobile from '../Common/DesiredHeader.mobile'
import { CustomRadio } from '../CustomRadio'
import Loading from 'app/components/loading'

const initType = {
  1: 'salary_ranges_lists',
  2: 'daily_salary_range_lists',
  3: 'hourly_salary_range_lists'
}

const formatData = (data, label, value) => {
  return data?.map((item) => ({
    label: item[label],
    value: item[value]
  }))
}

const DesiredJob = (props: { lang: any; config: any; initLoading?: boolean }) => {
  const { lang = {}, config } = props
  const errorCode = lang.errorcode || {}
  const profile = lang.profile || {}
  const { push } = useContext(LinkContext)
  const pathname = usePathname()
  const country = getCountryKey()
  const langKey = getLang()
  const chatProcess = isChatProcess()
  const { userInfo } = useMainResumeContext()
  const isJobPreferences = useMemo(() => {
    if (userInfo?.is_job_preferences) {
      return true
    }
    return false
  }, [userInfo])

  const [currencyLists] = useMemo(() => {
    return [getCurrencyList(config), getCountryList(config)]
  }, [config])
  const salaryTypeLists = useMemo(() => {
    return initSalaryTypeLists(config?.salary_type_lists || []).filter((item) => item?.id != 4)
  }, [config])
  const arrangementData = formatData(config.work_arrangement_lists, 'name', 'id')

  const [preference, setPreference] = useState(null)
  const [loading, setLoading] = useState(false)
  const [maxSalaryOptions, setMaxSalaryOptions] = useState([])
  const [needNextStep, setNeedNextStep] = useState(true)
  const localCurrency = countryForCurrency(country) || ''
  const [bindMinSalary, setBindMinSalary] = useState(0)

  const [formData, setFormData] = useState({
    minSalary: null,
    maxSalary: null,
    workLocation: null,
    salaryCurrency: null,
    jobFunction: { id: undefined, value: '' },
    arrangement: 1,
    salaryType: null
  })

  const disabledSubmit = useMemo(() => {
    // console.log('formData', formData)
    const checkJobFunction = !formData.jobFunction.id
    if (checkJobFunction) return true

    if (!formData.arrangement) return true

    if (Number(formData.arrangement) != 3 && !formData.workLocation) return true

    if (Number(formData.arrangement) != 3 && formData.salaryType) {
      if (!formData.minSalary || !formData.maxSalary) return true
    }

    if (Number(formData.arrangement) == 3 && formData.salaryCurrency) {
      if (!formData.salaryType || !formData.minSalary || !formData.maxSalary) return true
    }

    return false
  }, [formData])

  // console.log('disabledSubmit', disabledSubmit)

  const getMaxSalaryOptions = (minSalary) => {
    if (!formData.salaryType) return []
    const salaryCurrency = formData.salaryCurrency || localCurrency
    const maxSalaryOptions = getSalaryOptions(
      config[initType[formData.salaryType]].find(
        (el) => el.currency_id === currencyLists.find((el) => el.key === salaryCurrency).id
      ),
      Number(minSalary),
      true
    )

    const maxSalaryOrg = maxSalaryOptions?.length > 0 ? maxSalaryOptions[0].value : null
    setFormData({ ...formData, maxSalary: maxSalaryOrg })
    setMaxSalaryOptions(maxSalaryOptions)
  }

  const minSalaryOptions = useMemo(() => {
    if (!formData.salaryType) return []
    const salaryCurrency = formData.salaryCurrency || localCurrency
    return getSalaryOptions(
      config[initType[formData.salaryType]].find(
        (el) => el.currency_id === currencyLists.find((el) => el.key === salaryCurrency)?.id
      )
    )
  }, [
    JSON.stringify(currencyLists),
    JSON.stringify(config.salary_ranges_lists),
    formData.salaryCurrency,
    formData.salaryType
  ])

  const getJobsPreferences = (userDetail) => {
    const _preferences = userDetail?.job_preferences
    if (!_preferences.length) return
    const _preference = _preferences[0] || {}
    setPreference(_preference)
    // console.log('_preference', _preference)

    const {
      salary_range_from,
      salary_range_to,
      location_id,
      work_arrangement,
      currency_key,
      salary_type,
      daily_salary_range_from,
      daily_salary_range_to,
      hourly_salary_range_from,
      hourly_salary_range_to,
      pref_country_id,
      pref_region_id,
      function_job_title_id
    } = _preference

    const { id = '' } = salary_type || {}
    const from =
      id === 1 ? salary_range_from : id == 2 ? daily_salary_range_from : hourly_salary_range_from
    const to = id === 1 ? salary_range_to : id == 2 ? daily_salary_range_to : hourly_salary_range_to

    const _formData = {
      ...formData,
      minSalary: parseInt(from) || null,
      maxSalary: parseInt(to) || null,
      salaryType: isJobPreferences ? salary_type?.id : null,
      arrangement: work_arrangement?.id,
      salaryCurrency: isJobPreferences ? currency_key : null,
      jobFunction: {
        id: function_job_title_id,
        value: getValueById(config, function_job_title_id, 'function_job_title_id') ?? ''
      }
    }

    if (work_arrangement?.id === 3) {
      _formData.salaryCurrency = currency_key
    }
    // _formData.workLocation = {
    //   id: pref_country_id || pref_region_id || location_id,
    // }
    // console.log('_formData', _formData)
    setFormData({ ...formData, ..._formData })
  }

  useEffect(() => {
    addEventTag('new_user_direct_register')
    const eventTokens = {
      sg: 'gvmfx8',
      jp: '2vcvyj',
      ph: '7isidg',
      com: 'w0luou'
    }
    trackAdjustEventBySite(eventTokens)
  }, [])

  useEffect(() => {
    if (userInfo?.id) {
      getJobsPreferences(userInfo)
      const { is_resume, is_educations, is_work_experiences, is_basic_info } = userInfo
      // 是否进入一下步骤
      setNeedNextStep(!(is_resume || (is_educations && is_work_experiences)) || !is_basic_info)
    }
  }, [userInfo])

  useEffect(() => {
    if (formData.minSalary) {
      getMaxSalaryOptions(formData.minSalary)
    }
  }, [formData.minSalary])
  useEffect(() => {
    if (bindMinSalary && minSalaryOptions?.length) {
      setBindMinSalary(0)
      setFormData((pre) => {
        return { ...pre, minSalary: minSalaryOptions[0].value }
      })
    }
  }, [bindMinSalary, minSalaryOptions])

  const getSalaryRangeKey = (salaryType) => {
    switch (salaryType) {
      case 1:
        return 'salary_range'
      case 2:
        return 'daily_salary_range'
      case 3:
        return 'hourly_salary_range'
      default:
        return ''
    }
  }

  const getSubmitData = () => {
    let params = {
      job_title: formData.jobFunction.value || '',
      function_job_title_id: formData.jobFunction.id,
      currency_key: formData.salaryCurrency,
      country_id: Number(formData.arrangement) < 3 ? getCountryId() : 241,
      work_arrangement_id: formData.arrangement || undefined
    }

    if (formData.salaryType) {
      params['salary_type_id'] = formData.salaryType
    }

    if (Number(formData.arrangement) == 3) {
      params['language_ids'] = '1'
    }

    if (Number(formData.arrangement) != 3) {
      params = addParams(params, formData.workLocation)
    }

    const salaryRangeKey = getSalaryRangeKey(formData.salaryType)
    if (salaryRangeKey) {
      params[`${salaryRangeKey}_from`] = Number(formData.minSalary)
      params[`${salaryRangeKey}_to`] = Number(formData.maxSalary)
    }

    const _params = cloneDeep(removeEmptyOrNullValues(params))

    if (!formData.salaryType && preference?.salary_type?.id) {
      const salaryRangeKey = getSalaryRangeKey(preference?.salary_type?.id)
      if (salaryRangeKey) {
        _params[`${salaryRangeKey}_from`] = null
        _params[`${salaryRangeKey}_to`] = null
        _params['salary_type_id'] = preference?.salary_type?.id
      }
    }

    return _params
  }

  const onSubmit = async () => {
    const params = getSubmitData()
    console.log('params', { params, preference })
    setLoading(true)
    addEventTag('new_user_direct_preference_finish')
    if (preference?.id) {
      updateUserPreferencesService({
        preferenceId: preference?.id,
        params
      })
        .then((res) => {
          if (res?.data) {
            jumpPage()
          }
        })
        .finally(() => setLoading(false))
    } else {
      createUserPreferencesService({ params })
        .then((res) => {
          if (res.data) {
            jumpPage()
          }
        })
        .finally(() => setLoading(false))
    }
  }

  const onContinue = () => {
    // console.log('continue')
    const params = getSubmitData()
    // console.log('params', { params, preference })
    const searchWords = getUrlSearchString(2)
    setLoading(true)
    addEventTag('new_user_chat_preference_next')
    if (preference?.id) {
      updateUserPreferencesService({
        preferenceId: preference?.id,
        params
      })
        .then((res) => {
          if (res?.data) {
            if (needNextStep) {
              push(`${pathname}${searchWords}`)
            } else {
              jumpPage()
            }
          }
        })
        .finally(() => setLoading(false))
    } else {
      createUserPreferencesService({ params })
        .then((res) => {
          if (res.data) {
            if (needNextStep) {
              push(`${pathname}${searchWords}`)
            } else {
              jumpPage()
            }
          }
        })
        .finally(() => setLoading(false))
    }
  }

  // console.log('minSalaryOptions', minSalaryOptions)

  return (
    <>
      <div className={classNames(styles.desired, 'yoloJobseekerCompletedSelect')}>
        <div className={styles.desiredContainer}>
          <DesiredHeaderMobile lang={lang} langKey={langKey} />
          {props?.initLoading ? (
            <div style={{ minHeight: 'calc(100vh - 180px)', position: 'relative', width: '100%' }}>
              <Loading />
            </div>
          ) : (
            <>
              <div className={styles.container}>
                <div className={styles.content}>
                  <div className={styles.welcome}>{profile.desired?.wellcome}</div>
                  <div className={styles.form}>
                    <div className={styles.form_item}>
                      <div className={styles.form_label}>{profile?.JobTitleText}</div>
                      <div className={styles.form_value}>
                        <JobFunction
                          lang={lang}
                          value={formData.jobFunction}
                          onChange={(value) => setFormData({ ...formData, jobFunction: value })}
                        />
                      </div>
                    </div>

                    <div className={styles.form_item}>
                      <div className={styles.form_label}>{profile?.workArrangement}</div>
                      <div className={styles.form_value}>
                        <div className={styles.radioGroup}>
                          {arrangementData?.map((item) => (
                            <CustomRadio
                              checked={item.value == formData.arrangement}
                              onChange={(event) => {
                                let salaryCurrency = isJobPreferences ? localCurrency : null
                                let isRemote = Number(event.target.value) === 3

                                setFormData({
                                  ...formData,
                                  arrangement: event.target.value,
                                  minSalary: null,
                                  maxSalary: null,
                                  salaryType: null,
                                  salaryCurrency,
                                  // clear workLocation when user opt remote job
                                  workLocation: isRemote ? null : formData.workLocation
                                })
                              }}
                              label={item.label}
                              value={item.value}
                              key={item.value}
                            />
                          ))}
                        </div>
                      </div>
                    </div>

                    {formData.arrangement != 3 && (
                      <div className={styles.form_item}>
                        <div className={styles.form_label}>{profile.desired?.workLocation}</div>
                        <div className={styles.form_value}>
                          <Cascader
                            placeholder={profile.desired?.workLocationPlaceholder}
                            preference={preference}
                            onChange={(location) => {
                              setTimeout(() => {
                                console.log('location123', location)
                                setFormData({ ...formData, workLocation: location })
                              }, 0)
                            }}
                            suffixIcon={ArrowIcon}
                          />
                        </div>
                      </div>
                    )}

                    <div className={styles.form_item}>
                      <div className={styles.form_label}>
                        {profile.desired?.salaryRange}{' '}
                        <span className={styles.form_optional}>({profile.optional})</span>
                      </div>
                      <div className={styles.form_value + ' ' + styles.form_salary}>
                        {formData.arrangement == 3 && (
                          <Select
                            style={{ height: '42px' }}
                            options={currencyLists}
                            suffixIcon={<ArrowIcon />}
                            popupClassName='selectGlobalPopupClassName'
                            placeholder={profile.desired?.salaryCurrencyPlaceholder}
                            value={formData.salaryCurrency}
                            className={styles.form_salaryType}
                            allowClear
                            onChange={(value) => {
                              setBindMinSalary(value ? Math.random() : 0)
                              setFormData({
                                ...formData,
                                salaryCurrency: value,
                                salaryType: value ? 1 : null,
                                minSalary: null,
                                maxSalary: null
                              })
                            }}
                          />
                        )}
                        {formData?.salaryCurrency || formData?.arrangement != 3 ? (
                          <Select
                            style={{ height: '42px' }}
                            options={salaryTypeLists}
                            popupClassName='selectGlobalPopupClassName'
                            disabled={!formData.salaryCurrency && formData.arrangement == 3}
                            suffixIcon={<ArrowIcon />}
                            placeholder={profile.desired?.salaryType}
                            className={styles.form_salaryType}
                            value={formData.salaryType}
                            allowClear
                            onChange={(value) => {
                              setBindMinSalary(Math.random())
                              setFormData({
                                ...formData,
                                salaryType: value,
                                minSalary: null,
                                maxSalary: null
                              })
                            }}
                          />
                        ) : null}
                        {formData?.salaryType ? (
                          <div className={styles.form_salaryRange}>
                            <Select
                              disabled={!minSalaryOptions?.length}
                              style={{ height: '42px' }}
                              popupClassName='selectGlobalPopupClassName'
                              options={minSalaryOptions}
                              suffixIcon={<ArrowIcon />}
                              placeholder={profile.desired?.salaryFrom}
                              className={styles.form_minSalary}
                              value={formData.minSalary}
                              onChange={(value) => {
                                setFormData({ ...formData, minSalary: value })
                              }}
                            />
                            <Select
                              disabled={
                                !maxSalaryOptions?.length ||
                                !maxSalaryOptions?.length ||
                                !formData.minSalary
                              }
                              style={{ height: '42px' }}
                              popupClassName='selectGlobalPopupClassName'
                              options={maxSalaryOptions}
                              suffixIcon={<ArrowIcon />}
                              placeholder={profile.desired?.salaryTo}
                              className={styles.form_maxSalary}
                              value={formData.maxSalary}
                              onChange={(value) => {
                                setFormData({ ...formData, maxSalary: value })
                              }}
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <SafeInfo lang={lang} />
                </div>
              </div>
              <div className={styles.submitWrapperFixed}></div>
              {chatProcess ? (
                <div className={styles.submitWrapper}>
                  <LoadingButton
                    className={classNames(styles.submitBtn)}
                    loading={loading}
                    disabledSubmit={disabledSubmit || loading}
                    onSubmit={onContinue}
                    text={profile.continue}
                  />
                </div>
              ) : (
                <div className={styles.submitWrapper}>
                  <LoadingButton
                    className={classNames(styles.submitBtn)}
                    loading={loading}
                    disabledSubmit={disabledSubmit || loading}
                    onSubmit={onSubmit}
                    text={needNextStep ? profile.desired?.button2 : profile.confirm}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}

export default DesiredJob
